import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"

const NotFoundPage = () => {
  const domain = "https://kurkuru-jodo.juggling-pintcle.com"
  return (
    <Layout>
      <SEO
        lang="ja"
        title="ぴんとくるくる浄土 - 404: Not found"
        description="404: Not found"
        picUrl={domain + "/og-image/philosophy.jpg"}
      />
      <Section>
        <Title>NOT FOUND</Title>
        <Desc>ページが見つかりませんでした</Desc>

        <Left>
          <Link to="/">&gt; トップに戻る</Link>
        </Left>
      </Section>
    </Layout>
  )
}

export default NotFoundPage

const Section = styled.section`
  margin-bottom: 60px;
  padding: 0 14px;
  height: 400px;
`

const Title = styled.h1`
  font-size: 2.25rem;
  margin: 8px auto 40px;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
`

const Desc = styled.p`
  margin: 8px auto 40px;
  text-align: center;
`

const Left = styled.div`
  text-align: right;
  text-decoration: underline;
`
